body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h2 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$large-up} {
font-size: 27px;

}
}
h3 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
h4 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
h5 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
h6 {
color: var(--clr-94015);
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
.button {
font-family: 'Khula';
font-weight: 600;
font-style: normal;
line-height: 1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-94015);
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 34;
font-family: 'Cinzel';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
summary {
font-family: 'Merriweather';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-94013);}
a:hover {color: var(--clr-94014);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-94015);
color: #ffffff;
padding: 5px 15px;

 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-94015);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-94015);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-94016);
color: var(--clr-94015);
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-94016);
color: var(--clr-94015);
 }
.MES5 {
background-color: var(--clr-94016);
color: var(--clr-94015);
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: var(--clr-94015);
 }
 }
cite.MEC5{
color: var(--clr-94015);
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-94017);
 }
/* Alternate:7 */
.MES7 {
background-color: #ffffff;
color: var(--clr-94015);
 }
.MES7 {
background-color: #ffffff;
color: var(--clr-94015);
 }
cite.MEC7{
color: var(--clr-94015);
}
/* Light:8 */
.MES8 {
background-color: var(--clr-94018);
 }
/* Light:9 */
.MES9 {
background-color: var(--clr-94018);
 }
.MES9 {
background-color: var(--clr-94018);
 }
/* Header:10 */
.MES10 {
background-color: var(--clr-94019);
color: var(--clr-94015);
@media #{$large-up} {
min-height: 50px;};
 }
.MES10 {
background-color: var(--clr-94019);
color: var(--clr-94015);
@media #{$large-up} {
min-height: 50px;};
h1.MEC10 { @media #{$large-up} { font-size: 28.5px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 25.65px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 17.1px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 13.3px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 15.2px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 13.3px; }; }
 }
a.MEC10 { color: var(--clr-94015);
 }
cite.MEC10{
color: var(--clr-94015);
}
/* Main Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: var(--clr-94015);
font-size: 14.4px;
text-transform: uppercase;
}
 &:hover > a.MEC11{color: var(--clr-94020);
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 25;
border-style: solid;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
border-color: 13;
border-style: solid;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

@media #{$medium-up} {
& .sub-menu .menu-item a{padding: 15px 10px;}

}


&.horizontal .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC11{background-color: #ffffff; &:hover {background-color: 29;}
}
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: var(--clr-94021);
text-transform: uppercase;
}
  }

}
}
 }
/* footer:12 */
.MES12 {
background-color: var(--clr-94015);
color: var(--clr-94018);
padding: 40px 0 0 0;

 }
.MES12 {
background-color: var(--clr-94015);
color: var(--clr-94018);
padding: 40px 0 0 0;

h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: var(--clr-94018);
 }
 }
a.MEC12 { color: var(--clr-94018);
&:hover { color: var(--clr-94013);}
 }
cite.MEC12{
color: var(--clr-94018);
}
/* Footer Separator:13 */
.MES13 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94017);}
& > hr {border-top-style: dotted;}
 }
/* Footer Menu:14 */
nav.me-Menu.MES14 {
& .menu-item.MEC14, & .menu-item.MEC14 > div.MEC14{ & > a.MEC14{color: var(--clr-94022);
}
 &:hover > a.MEC14{color: var(--clr-94017);
}
 }
&.horizontal > .menu-item.MEC14 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC14 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC14 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC14 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* photo:16 */
.MES16 {
background-color: #ffffff;
color: #000000;
padding: 2px;

@media #{$medium-up} {
padding: 10px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-94016);
 }
.MES16 {
background-color: #ffffff;
color: #000000;
padding: 2px;

@media #{$medium-up} {
padding: 10px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-94016);
 }
a.MEC16 { color: #000000;
&:hover { color: var(--clr-94013);}
 }
cite.MEC16{
color: #000000;
}
/* Photo Button:17 */
.MES17 {
background-color: var(--clr-94024);
&:hover {background-color: var(--clr-94023);}
color: #000000;
&:hover { color: var(--clr-94017);}
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-94016);
 }
/* Read More:18 */
.MES18 {
background-color: var(--clr-94016);
&:hover {background-color: var(--clr-94016);}
color: var(--clr-94020);
&:hover { color: var(--clr-94020);}
padding: 5px 10px;

 }
/* News Blocks:19 */
.MES19 {
background-color: var(--clr-94017);
padding: 0 0 10px 0;

border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-94016) transparent;
 }
.MES19 {
background-color: var(--clr-94017);
padding: 0 0 10px 0;

border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-94016) transparent;
 }
/* Home Carousel:20 */
.MES20 {
& .slider-arrow {color: var(--clr-94022);
font-size: 10px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}& .slider-arrow:hover{color: var(--clr-94025);
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 28;
border-radius: 50%;
border-style:solid;
border-width:5px;
@media #{$medium-up} {
border-width: 5px;
};
@media #{$large-up} {
border-width: 5px;};
&:hover{border-color: 28;}
background-color: 25;
background-clip: padding-box;
&:hover {background-color: 39;}
width:20px;
height:20px;
@media #{$medium-up} {
width:20px;
height:20px;
};
@media #{$large-up} {
width:20px;
height:20px;
};
&:hover{background-color: 39;}
}}
& .slick-dots{bottom:100px;
@media #{$medium-up} {
bottom: 75px;};
@media #{$large-up} {
bottom: 80px;};
} }
/* Buying and Selling:21 */
.MES21 {
background-color: var(--clr-94016);
&:hover, &.hover { background-color: var(--clr-94016);}
color: #000000;
font-size: 16px;
padding: 10px;

 }
.MES21 {
background-color: var(--clr-94016);
&:hover, &.hover { background-color: var(--clr-94016);}
color: #000000;
font-size: 16px;
padding: 10px;

 }
a.MEC21 { color: #000000;
&:hover { color: var(--clr-94013);}
 }
cite.MEC21{
color: #000000;
font-size: 16px;
}
/* Home Block Strip:22 */
.MES22 {
background-color: #000000;
color: var(--clr-94017);
 }
.MES22 {
background-color: #000000;
color: var(--clr-94017);
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: var(--clr-94017);
 }
 }
cite.MEC22{
color: var(--clr-94017);
}
/* Sold:23 */
.MES23 {
background-color: var(--clr-94026);
color: var(--clr-94015);
padding: 20px;

 }
.MES23 {
background-color: var(--clr-94026);
color: var(--clr-94015);
padding: 20px;

h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: var(--clr-94015);
 }
 }
cite.MEC23{
color: var(--clr-94015);
}
/* Home Slider:24 */
.MES24 {
& .slider-arrow {background-color: 24;
color: #000000;
font-size: 35px;
@media #{$medium-up} {
font-size: 35px;
};
@media #{$large-up} {
font-size: 35px;
};
}& .slider-arrow:hover{color: var(--clr-94025);
}& .slick-dots{bottom:30px;
} }
/* product body:25 */
.MES25 {
background-color: #000000;
color: var(--clr-94017);
 }
.MES25 {
background-color: #000000;
color: var(--clr-94017);
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: var(--clr-94018);
 }
 }
cite.MEC25{
color: var(--clr-94017);
}
/* Enquire Button Gold:26 */
.MES26 {
background-color: var(--clr-94013);
&:hover {background-color: var(--clr-94022);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
border-radius: 0 15px;
padding: 10px;

border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Content wrap:28 */
.MES28 {
background-color: #ffffff;
padding: 0;

 }
.MES28 {
background-color: #ffffff;
padding: 0;

 }
a.MEC28 { color: #000000;
&:hover { color: var(--clr-94013);}
 }
/* stock button:29 */
.MES29 {
background-color: var(--clr-94022);
color: var(--clr-94017);
 }
.MES29 {
background-color: var(--clr-94022);
color: var(--clr-94017);
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: var(--clr-94017);
 }
 }
a.MEC29 { color: #ffffff;
&:hover { color: var(--clr-94018);}
 }
cite.MEC29{
color: var(--clr-94017);
}
/* side menu:30 */
nav.me-Menu.MES30 {
.menu-item.MEC30{ &:hover {background-color: var(--clr-94016);}
}
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: var(--clr-94020);
font-size: 14.4px;
text-transform: capitalize;
}
 &:hover > a.MEC30{color: var(--clr-94020);
}
 }
&.horizontal > .menu-item.MEC30 { border:0;
border-color: var(--clr-94016);
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;
border-color: var(--clr-94016);
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Main Content:31 */
.MES31 {
background-color: #ffffff;
color: #000000;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES31 {
background-color: #ffffff;
color: #000000;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC31 { color: var(--clr-94013);
&:hover { color: var(--clr-94027);}
 }
cite.MEC31{
color: #000000;
}
/* Go Back Button:32 */
.MES32 {
background-color: transparent;
&:hover {background-color: var(--clr-94022);}
color: var(--clr-94023);
&:hover { color: #ffffff;}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-94023);
 }
/* Site Header:33 */
.MES33 {
padding: 30px 0;

@media #{$medium-up} {
padding: 0 0 20px 0;

}
@media #{$large-up} {
padding: 0 0 30px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023);}
& > hr {border-top-style: solid;}
 }
/* News list item:34 */
.MES34 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-94020);}
padding: 15px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: var(--clr-94020);
 }
.MES34 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-94020);}
padding: 15px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: var(--clr-94020);
 }
a.MEC34 { color: #000000;
&:hover { color: var(--clr-94017);}
 }
/* Page Separator:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Dark:36 */
.MES36 {
background-color: var(--clr-94021);
 }
/* Dark:37 */
.MES37 {
background-color: var(--clr-94021);
 }
.MES37 {
background-color: var(--clr-94021);
 }
/* Accordion:38 */
details.MES38 {
& > summary{background-color: #ffffff;
}
color: var(--clr-94015);
& > article { color: var(--clr-94015);
 }
 }
/* Accordion style:39 */
.MES39 {
background-color: #ffffff;
color: var(--clr-94015);
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES39 {
background-color: #ffffff;
color: var(--clr-94015);
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC39 { color: var(--clr-94028);
&:hover { color: var(--clr-94013);}
 }
cite.MEC39{
color: var(--clr-94015);
}
/* newslist:40 */
.MES40 {
padding: 15px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94018);}
& > hr {border-top-style: solid;}
 }
/* Accordion:41 */
details.MES41 {
& > summary{background-color: #ffffff;
}
color: var(--clr-94015);
& > article { color: var(--clr-94015);
 }
& > summary { color: var(--clr-94015);
font-size:19.18px;
 }
 }
/* Buying and selling:42 */
.MES42 {
padding: 10px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023);}
& > hr {&:hover { border-color: var(--clr-94023);} }
& > hr {border-top-style: solid;}
 }
/* Feature Coins:43 */
.MES43 {
 }
.MES43 {
 }
/* View E Catalogue:44 */
.MES44 {
background-color: var(--clr-94029);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 14.4px;
border-radius: 5px;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-94017);
 }
/* Enquire Button Black:45 */
.MES45 {
background-color: var(--clr-94015);
&:hover {background-color: var(--clr-94022);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
border-radius: 0 15px;
padding: 10px 20px;

border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Home Slider Padding:46 */
.MES46 {
color: var(--clr-94022);
padding: 15px;

@media #{$medium-up} {
padding: 18px 15px;

}
 }
.MES46 {
color: var(--clr-94022);
padding: 15px;

@media #{$medium-up} {
padding: 18px 15px;

}
 }
cite.MEC46{
color: var(--clr-94022);
}
/* Slider wrap:47 */
.MES47 {
background-color: var(--clr-94016);
padding: 0;

 }
.MES47 {
background-color: var(--clr-94016);
padding: 0;

 }
/* Homepage Bottom Line:48 */
.MES48 {
padding: 5px 0 0 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Popup Join Form:49 */
.MES49 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://test.coinworks.com.au/img/6043/4167');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 30px 15px;

 }
.MES49 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://test.coinworks.com.au/img/6043/4167');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 30px 15px;

 }
cite.MEC49{
color: #ffffff;
}
/* Page product panel:51 */
.MES51 {
background-color: var(--clr-94016);
&:hover, &.hover { background-color: var(--clr-94016);}
color: var(--clr-94015);
font-size: 12.8px;
padding: 10px 10px 0 10px;

 }
.MES51 {
background-color: var(--clr-94016);
&:hover, &.hover { background-color: var(--clr-94016);}
color: var(--clr-94015);
font-size: 12.8px;
padding: 10px 10px 0 10px;

 }
cite.MEC51{
color: var(--clr-94015);
font-size: 12.8px;
}
/* Email template menu:52 */
.MES52 {
background-color: #ffffff;
color: var(--clr-94015);
font-size: 14.4px;
padding: 20px 10px 0 10px;

 }
.MES52 {
background-color: #ffffff;
color: var(--clr-94015);
font-size: 14.4px;
padding: 20px 10px 0 10px;

h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: var(--clr-94015);
 }
 }
a.MEC52 { color: var(--clr-94031);
&:hover { color: var(--clr-94032);}
 }
.MEC52 li { 
font-size: 14.4px;

 }
cite.MEC52{
color: var(--clr-94015);
font-size: 14.4px;
}
/* Email seperator:53 */
.MES53 {
padding: 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* :54 */
.MES54 {
 }
.MES54 {
 }
/* Blue ANDA:55 */
.MES55 {
background-color: var(--clr-94031);
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
padding: 15px;

 }
.MES55 {
background-color: var(--clr-94031);
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
padding: 15px;

 }
/* Shade 2:56 */
.MES56 {
background-color: var(--clr-94033);
 }
/* Shade 2:57 */
.MES57 {
background-color: var(--clr-94033);
 }
.MES57 {
background-color: var(--clr-94033);
 }
/* Shade 3:58 */
.MES58 {
background-color: var(--clr-94032);
 }
/* Shade 3:59 */
.MES59 {
background-color: var(--clr-94032);
 }
.MES59 {
background-color: var(--clr-94032);
 }
/* Light Blue ANDA:60 */
.MES60 {
background-color: var(--clr-94032);
color: #ffffff;
padding: 15px;

 }
.MES60 {
background-color: var(--clr-94032);
color: #ffffff;
padding: 15px;

 }
cite.MEC60{
color: #ffffff;
}
/* Site Header:61 */
.MES61 {
padding: 30px 0;

@media #{$medium-up} {
padding: 0 0 20px 0;

}
@media #{$large-up} {
padding: 0 0 30px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-94023);}
& > hr {border-top-style: solid;}
 }
/* Request Catalogue Button:62 */
.MES62 {
background-color: var(--clr-94013);
&:hover {background-color: var(--clr-94022);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Request Catalogue:63 */
.MES63 {
background-color: var(--clr-94013);
&:hover, &.hover { background-color: var(--clr-94022);}
color: #ffffff;
border-radius: 0 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
.MES63 {
background-color: var(--clr-94013);
&:hover, &.hover { background-color: var(--clr-94022);}
color: #ffffff;
border-radius: 0 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
cite.MEC63{
color: #ffffff;
}
/* coin mask:64 */
.MES64 {
background-color: var(--clr-94029);
color: #ffffff;
 }
.MES64 {
background-color: var(--clr-94029);
color: #ffffff;
h1.MEC64, h2.MEC64, h3.MEC64, h4.MEC64, h5.MEC64, h6.MEC64 { color: #ffffff;
 }
 }
cite.MEC64{
color: #ffffff;
}
/* Product Accrodion:65 */
details.MES65 {
& > summary{padding: 20px 15px;}

@media #{$medium-up} {
& > summary{padding: 20px 25px;}

}
@media #{$large-up} {
& > summary{padding: 20px 45px;}

}
& > article{padding: 0 10px 20px 15px;}

@media #{$medium-up} {
& > article{padding: 0 20px 20px 25px;}

}
@media #{$large-up} {
& > article{padding: 0 40px 20px 45px;}

}
& > summary { font-size:19.18px;
 }
 }
/* Related Hover:66 */
.MES66 {
&:hover, &.hover { background-color: var(--clr-94017);}
 }
.MES66 {
&:hover, &.hover { background-color: var(--clr-94017);}
 }
/* Product Accrodion grey:67 */
details.MES67 {
& > summary{background-color: var(--clr-94016);
}
& > summary{padding: 20px 15px;}

@media #{$medium-up} {
& > summary{padding: 20px 25px;}

}
@media #{$large-up} {
& > summary{padding: 20px 45px;}

}
& > article{padding: 0 10px 20px 15px;}

@media #{$medium-up} {
& > article{padding: 0 20px 20px 25px;}

}
@media #{$large-up} {
& > article{padding: 0 40px 20px 45px;}

}
& > summary { font-size:19.18px;
 }
 }
/* Go Back Button:68 */
.MES68 {
background-color: transparent;
&:hover {background-color: var(--clr-94022);}
color: #ffffff;
&:hover { color: var(--clr-94018);}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 40px 5px 40px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Responsive menu style:69 */
nav.responsive-menu {
.menu-item.MEC69{background-color: var(--clr-94029);}
& .menu-item.MEC69, & .menu-item.MEC69 > div.menu-item-wrap{ & > a.MEC69, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC69 { border:0;
border-color: var(--clr-94022);border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 3px 20px;}

& .sub-menu{.menu-item.MEC69{background-color: 14;}
& .menu-item.MEC69, & .menu-item.MEC69 > div.menu-item-wrap{ & > a.MEC69, & > i{color: 31;
text-align: left;
}
  }
 .sub-menu {}}

 }
